.newPatientFormContainer {
  display: inline-grid !important;
  grid-template-columns: auto auto auto;
  grid-template-areas: 
    ". . address"
    ". . address"
    ". . address"
    ". . ."
    ". . ."
    ". . ."
    ". . submit";
}
@media (max-width: 720px) {
  .newPatientFormContainer {
    grid-template-columns: auto auto;
    grid-template-areas: 
      ". address"
      ". address"
      ". address"
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". submit";
  }
}
@media (max-width: 540px) {
  .newPatientFormContainer {
    grid-template-columns: auto;
    grid-template-areas: 
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "address"
      "submit";
  }
}
.newPatientAddress {
  grid-area: address;
}
.newPatientSubmit {
  grid-area: submit;
}
.newPatientFormLabel {
  cursor: help;
  display: block;
  font-weight: bold;
}
.newPatientFormInfo {
  display: block;
  color: grey;
}

.newPatientFormSelect {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}
.newPatientFormDate {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}
.newPatientFormText {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}

.newPatientFormTextAddr {
  display: block;
  min-height: 36px;
  min-width: 137px;
  margin: 3px auto;
}

.newPatientFormButton {
  display: inline-block;
  background-color: white;
  border: 2px solid #E7E7E7;
  color: black;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0 3px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 137px;
}
.newPatientFormButton:hover {
  background-color: #E7E7E7;
}

.newPatientForm {
  display: block;
  margin: 9px;
}

#customPatientFormValidation{
  color: red;
}

.patientsTableHiddenColumn {
  display: none;
  animation: scaleDisplayClaimsDetails .3s;
}

#patientsTableExtendButton {
  display: inline-block;
}

#patientsTableHideButton {
  display: none;
}

@keyframes scaleDisplayPatientsDetails {
  0% {
    opacity: 0;
    /*transform: scale(0);
    -webkit-transform: scale(0);*/
  }

  100% {
    opacity: 1;
    /*transform: scale(1);
    -webkit-transform: scale(1);*/
  }
}

#patientsTable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#patientsTable td, #patientsTable th {
  border: 1px solid #DDDDDD;
  padding: 5px;
}

#patientsTable tr:nth-child(even){
  /*background-color: #F2F2F2;*/
  background-color: rgb(242, 242, 242, 0.5);
}

#patientsTable tr:hover {
  /*background-color: #DDDDDD;*/
  background-color: rgb(221, 221, 221, 0.5);
}

#patientsTable th {
  text-align: left;
  background-color: #3490DC;
  /*background-color: rgb(52, 144, 220, 0.9);*/
  color: white;
}

#formOtherNameText, #formOtherValueText {
  display: block;
}