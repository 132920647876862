.newClaimFormLabel {
  cursor: help;
  display: block;
  font-weight: bold;
}
.newClaimFormInfo {
  display: block;
  color: grey;
}

.newClaimFormSelect {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}
.newClaimFormDate {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}
.newClaimFormText {
  display: inline-block;
  min-height: 36px;
  min-width: 137px;
}
.newClaimFormButton {
  display: inline-block;
  background-color: white;
  border: 2px solid #E7E7E7;
  color: black;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0 3px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 137px;
}
.newClaimFormButton:hover {
  background-color: #E7E7E7;
}
.newClaimFormButtonDisabled {
  display: inline-block;
  border: 2px solid #E7E7E7;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0 3px;
  width: 137px;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  cursor: not-allowed;
  color: #DDDDDD !important;
  background-color: #EEEEEE;
}
.newClaimFormLink {
  display: inline-block;
}

.newClaimFormPart1 {
  display: block;
}
.newClaimFormPart2 {
  display: none;
}
.newClaimFormPart3 {
  display: none;
}
.newClaimFormPart4 {
  display: none;
}

#newClaimFormDetails {
  display: none;
  animation: scaleDisplayClaimsDetails .3s;
}

.newClaimFormDetailsButton{
  text-decoration: none;
  font-size: 16px;
  margin: 0 3px;
/*  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;*/
  cursor: pointer;
}
.newClaimFormDetailsButton:hover {
  font-weight: bold;
}

#customClaimFormValidation{
  color: red;
}

.sigImage {
  z-index: 1;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  cursor: pointer;
}

.sigImage:hover {
  z-index: 1;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
}

#newClaimSignatureField {
  z-index: 2;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255);
}

.fixedRightBottom {
  z-index: 3;
  position: fixed;
  bottom: 3vh;
  right: 3vw;
}

#bigSignatureImageContainer {
  z-index: 2;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  overflow-y: scroll;
}

#bigSignatureImage {
  z-index: 2;
  border-style: none;
  display: block;
  margin: auto;
  padding-top: 34vh;
  padding-bottom: 8vh;
}

.claimsTableHiddenColumn {
  display: none;
  animation: scaleDisplayClaimsDetails .3s;
}

#claimsTableExtendButton {
  display: inline-block;
}

#claimsTableHideButton {
  display: none;
}

@keyframes scaleDisplayClaimsDetails {
  0% {
    opacity: 0;
    /*transform: scale(0);
    -webkit-transform: scale(0);*/
  }

  100% {
    opacity: 1;
    /*transform: scale(1);
    -webkit-transform: scale(1);*/
  }
}

#claimsTable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#claimsTable td, #claimsTable th {
  border: 1px solid #DDDDDD;
  padding: 5px;
}

#claimsTable tr:nth-child(even){
  /*background-color: #F2F2F2;*/
  background-color: rgb(242, 242, 242, 0.5);
}

#claimsTable tr:hover {
  /*background-color: #DDDDDD;*/
  background-color: rgb(221, 221, 221, 0.5);
}

#claimsTable th {
  text-align: left;
  background-color: #3490DC;
  /*background-color: rgb(52, 144, 220, 0.9);*/
  color: white;
}

.newClaim select {
  max-width: 137px;
}

.claimHistoryTogglable {
	display: none;
}

.claimHistoryTogglableHeader {
	cursor: pointer;
}