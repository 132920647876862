
.relation-container {
    
    text-align: center;
    width: 100%;
    position: relative;
	z-index: 0;
	margin: 1em;
	display: inline-block;
	
}

.relation-element {

    display: inline-table;
    padding: 1em;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
    margin: 0;
    z-index: 0;
    
}

.relation-circle {
    
    color: black;
    background: #e7e7e7;
    background-image: linear-gradient(to bottom right, #e7e7e7, #d5d5d5);
    border: 0.15em solid #0F1C3F;
    height: 33em;
    width: 33em;
    padding: 3em;
    z-index: 1;
    margin-bottom: 5em;

}

.relation-subcircle {

    color: black;
    background: #b7b7b7;
    background-image: linear-gradient(to bottom right, #b7b7b7, #adacac);
    border: 0.1em solid #0F1C3F;
    height: 47%;
    width: 47%;
    z-index: 2;

}

.relation-smallcircle {

    color: white;
    background: #8e8e8e;
    background-image: linear-gradient(to bottom right, #8e8e8e, #787878);
    border: 0.1em solid #0F1C3F;
    height: 31%;
    width: 31%;
    z-index: 3;
    padding: 0.1em;

}

.relation-title {

    z-index: 10;
    /*transform: rotate(0deg);*/
    font-size: 0.65em;
    font-weight: bold;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    overflow-wrap: break-word;
    width: 75%;

}
