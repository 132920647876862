
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// Specific use (custom basic CSS sheets for global usage)
@import "./general.css";
@import "./claims.css";
@import "./patients.css";
@import "./account.css";
@import "./circles.css";
@import "./nav.css";

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
//$primary: #4D44CC;
$primary: #8a28d7;
//$primary: #4f21b7;
$primary-invert: findColorInvert($primary);
$info: #8A28D7;
$info-invert: findColorInvert($info);
$success: #77e8cb;
$success-invert: findColorInvert($success);
$warning: #ffe19d;
$warning-invert: findColorInvert($warning);
$danger: #ef476f;
$danger-invert: findColorInvert($danger);
$bland: #Cdd3CE;
$bland-invert: findColorInvert($bland);

// Set any other variables found at https://bulma.io/documentation/customize/variables/
$control-radius: 5px;
$tablet: 650px;
$size-1: 4rem;

// Fonts
@font-face {
    font-family: Delicious;
    src: url("/resources/fonts/delicious/Delicious-Roman.otf") format("opentype");
}

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "bland": ($bland, $bland-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma
@import "~bulma";
@import "~buefy/src/scss/buefy";

body {
    background-color: #ECF2FB !important;
    min-height: 100vh;
}
@media only screen and (max-width: 800px) {
    body {
        min-height: 120vh;
    }
    .navbar-end > .navbar-item {
        color: black !important;
    }
    .navbar-end > .navbar-item .is-active-dark {
        color: $primary !important;
    }
}

/*
MISC SCSS
 */
.full {
    height: 50vh;
}
.nav-container {
    //margin-right: 0;
}
.header-image{
    background-color: #4C46C7;
    background-image: url('../images/medical-billing-background-main.jpg');
    background-size:cover;
    background-position:top center;
}
.header{
    color:#ffffff;
    background-size:cover;
    background-position:top center;
}

.after-main {
    height: 100px;
    background-image: url("../images/curved-top-subpages.svg");
    -ms-background-image: none;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -100px;
}

.navbar {
    height: 60px;
    background-color: transparent;
}


.login{
    z-index: 2;
    display:flex;
    flex-direction:row;
    justify-content: center;
    background-color: #ECF2FB;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.login-form {
    width: 80%;
}

.login {
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    width:100%;
    flex-basis: 100%;
    max-width: 50%;
    min-width: 20%;
    box-sizing: border-box;
    background-color: #ECF2FB;
    padding: 8px 0 8px 0;
}
@media only screen and (min-width: 800px) {
    .login {
        display:flex;
        flex-direction:row;
        width:50%;
        flex-basis: 50%;
        border-radius:0 0 100px 100px;
    }
}
@media only screen and (max-width: 800px) {
    .login {
        width:100%;
        border-radius:0 0 0 100px;
        min-width: 80vw;
    }
}
@media only screen and (max-width: 450px) {
    body {
        max-width: 100vw;
    }
    .login {
        width:100%;
        min-width: 100vw;
    }
}
//.login-form{
//    display:flex;
//    flex-direction:row;
//    justify-content: space-between;
//    width:100%;
//    flex-basis: 100%;
//    box-sizing: border-box;
//    background-color: #ECF2FB;
//    padding: 8px 0 8px 0;
//}
//@media only screen and (min-width: 800px) {
//    .login-form{
//        display:flex;
//        flex-direction:row;
//        width:50%;
//        flex-basis: 50%;
//        border-radius:0 0 0 100px;
//        padding: 15px 0 10px 37px;
//    }
//}
.login form {
    display: flex;
    justify-content: center;
    width: 75%;
}
.login-navbar {
    width:50%;
}
//.login-navbar-submit {
//    width:20%;
//}

.is-active-dark {
    margin-bottom: -5px;
    background-color: whitesmoke;
    border-bottom: 3px solid $primary;
    color: $primary !important;
}

.vertical-center {
    //padding-top: 20vh;
    height: 70vh;
    display: flex;
    align-items: center;
}
.v-center {
    display: flex;
    align-items: center;
}
//
//.navbar-item {
//    margin: 0;
//    padding: 0;
//}

.main-title {
    margin-bottom: 20px;
}


.subpages-before{
    height: 60px;
    background-image: url("../images/curved-top-subpages.svg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -60px;
}
@media only screen and (min-width: 800px) {
    .subpages-before{
        height: 100px;
        background-image: url("../images/curved-top-subpages.svg");
        margin-top: -100px;
    }
}
@media only screen and (min-width: 1230px) {
    .subpages-before{
        height: 130px;
        margin-top:-120px;
    }
}
//header h1{
//    color:#ffffff;
//    text-align: center;
//    margin: 40px 0 60px 0;
//}
//@media only screen and (min-width: 500px) {
//    header h1{
//        margin: 50px 0 60px 0;
//    }
//}
//@media only screen and (min-width: 600px) {
//    header h1{
//        margin: 90px 0 110px 0;
//    }
//}
//@media only screen and (min-width: 800px) {
//    header h1{
//        margin: 90px 0 140px 0;
//    }
//}
//@media only screen and (min-width: 900px) {
//    header h1{
//        margin: 145px 0 180px 0;
//        font-size: 2.4em;
//    }
//}
//.subpages-header h1{
//    margin: 55px 0 60px 0;
//}
//@media only screen and (min-width: 600px) {
//    .subpages-header h1{
//        margin: 60px 0 70px 0;
//    }
//}
//@media only screen and (min-width: 800px) {
//    .subpages-header h1{
//        margin: 90px 0 110px 0;
//    }
//}
//@media only screen and (min-width: 900px) {
//    .subpages-header h1{
//        margin: 105px 0 115px 0;
//    }
//}

.benefits {
    list-style: none;
    padding: 0;
}
.benefits li{
    list-style-type: none;
    padding-left:35px;
    background-image: url('../images/bullet-pink.svg');
    background-size:17px 17px;
    background-position: 0 4px;
    background-repeat: no-repeat;
    font-size:1.1em;
    line-height: 1.3em;
    margin: 0.7em 0;
}

/* Footer */
.footer {
    padding: 3rem 1.5rem 3rem;
}
.footer-contact {
    box-sizing: border-box;
    padding-right: 5%;
    width: 35%;
}
.logo-footer {
    width: 135px;
    margin-bottom: 15px;
}
.footer-contact-info {
    color: #4D44CC;
    font-weight: 600;
}
.footer-social {
    box-sizing: border-box;
    padding-right: 5%;
    width: 25%;
}
.social-icon {
    width: 50px;
    margin-right: 12px;
}
.list-social {
    display: flex;
    justify-content: center;
}
.footer h3 {
    line-height: 1.3;
    color: #4C46C7;
    font-weight: bold;
    margin-bottom: 5px;
}
