.accountElement {
	padding: 3px;
	margin: 3px;
}

.accountInfoTitle {
	margin-top: 10px;
	color: grey;
}

.box.billink_logo{
	background-color: #FFFFFF;
    background-image: url('../images/billink_logo.png');
    background-repeat: no-repeat;
	background-size: 176px 65px;
	background-position: right 10px top 10px;
}