
.gpservButton {
	text-decoration: none;
	background-color: transparent;
	color: black;
	text-align: center;
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	cursor: pointer;
	width: 137px;
	border-bottom: 1px solid #DDDDDD !important;
	border-right: 1px solid #F2F2F2 !important;

	@media (min-width: 37.5em) {
		width: auto;
	}
}
.gpservButton:hover {
	background-color: #3490DC;
	color: white !important;
}
.gpservLink {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}
.tableContainer {
	max-width: 100%;
	overflow-x: scroll;
	background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0));
	/* Shadows */
	/* Shadow covers */
	background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
	background-attachment: local, local, scroll, scroll;
}

/* Spinners */
.spring-spinner, .spring-spinner * {
    box-sizing: border-box;
}

.spring-spinner {
    height: 60px;
    width: 60px;
}

.spring-spinner .spring-spinner-part {
    overflow: hidden;
    height: calc(60px / 2);
    width: 60px;
}

.spring-spinner  .spring-spinner-part.bottom {
    transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
    width: 60px;
    height: 60px;
    border: calc(60px / 7) solid transparent;
    border-right-color: #4d44cc;
    border-top-color: #4d44cc;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spring-spinner-animation 3s ease-in-out infinite;
    transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
    0% {
        border-width: calc(60px / 7);
    }
    25% {
        border-width: calc(60px / 23.33);
    }
    50% {
        transform: rotate(115deg);
        border-width: calc(60px / 7);
    }
    75% {
        border-width: calc(60px / 23.33);
    }
    100% {
        border-width: calc(60px / 7);
    }
}